export default {
	title: '网站名称',
	fuzhi:'复制',
	zongjine:'总计',
	h_title: {
		shouye: '首页',
		login: '登录',
		hudong: '活动中心',
		xianshangkefu: '线上客服',
		chongzhi: '充值',
		erduzhuanhuan: '额度转换',
		erduzhuanhuanTow: "虚拟货币充值",
		gerenzhongxin: '个人中心',
		gerenxinxi: '个人信息',
		bangdingshouji: '绑定手机',
		dailizhongxin: '代理中心',
		xiaoxi: '消息',
		tixian: '提现',
		baobiao_cunkuang: '存款记录',
		baobiao_tixian: '提现记录',
		baobiao_touzhu: '投注记录',
		baobiao_fanshui: '返水记录',
		baobiao_eduzhuanhuan: '额度转换记录',
		zhuce: '注册',
		xiugaixhifumima: '修改支付密码',
		huodongxiangqing: '活动详情',
		zizhufuwu: '自助服务',
		zizhufuwu_list: '帮助中心',
		zizhufuwu_list2: '下分教程',
		zizhufuwu_list3: '上分教程',
		msg: '通知公告'
	},
	kefuzhongxin: {
		title: '欢迎来到客服中心',
		titleTow: '24小时随时为您服务',
		zaixiankefu: '在线客服',
		Skype: 'Skype',
		Telegram: 'Telegram',
		Whatsapp: 'Whatsapp',
	},
	common: {
		shuaxin: '刷新成功',
		weihu: '正在维护中...',
		gengduo: '更多',
		xiangxi: '详情',
		tuichudenglu: '退出登陆',
		queren: '确认',
		quxiao: '取消',
		genghuan: '查看',
		qingshuru: '请输入',
		zanwujilv: '暂无记录',
		lingquchenggong: '领取成功',
		baobiao_status: ['', '待审核', '成功', '拒绝'],
		yuan: '元',
		denglu_title: '请登陆',
		denglu_mas: '您的未登陆，无法进行操作，请您重新登录'
	},
	kefu: {
		caozuozhinan: '操作指南',
		xianshangkefu: 'telegram客服',
		wx: '微信',
		tousudianhua: '投诉电话'
	},
	newList: {
		title1: '无记录',
		title2: '充值',
		title3: '转数快',
		title4: '便利商店增值',
		title5: '虚拟币支付',
		title6: '支付宝扫码充值',
		title7: '消费卷支付',
		title8: '充值金额：',
		title9: '请输入充值金额',
		title10: '付款姓名：',
		title11: '请输入付款姓名',
		title12: '充值赠送：',
		title13: '无送分储值（2倍流水）',
		title14: '无限储值赠送10%（10倍流水）',
		title15: '每日首笔储值赠送20%（20倍流水）',
		title16: '充值方式：',
		title17: '转数快',
		title18: '便利商店增值',
		title19: '虚拟币支付',
		title20: '支付宝扫码充值',
		title21: '获取收款银行资讯',
		title22: '返回重新输入',
		title23: ' * 注意：汇款需根据系统显示尾款进行汇款',
		title24: '不接受汇款方式：',
		title25: 'A 现金存款',
		title26: 'B ATM存款',
		title27: 'C 支票存款',
		title28: 'D 汇款额度少于100',
		title29: 'E 擅自进款，汇款金额与提交金额不匹配',
		title30: 'F 系统名字与银行名字不匹配',
		title31: '发生以上条例规定情况，一律不处理。',
		title32: '任何情况引起的误差，本公司概不负责，敬请体谅',
		title33: '收款银行',
		title34: '请记录以下收款资讯,并汇款',
		title35: '收款帐号：',
		title36: '收款帐户名：',
		title37: '收款二维码：',
		title38: '充值地址(TRC)：',
		title39: '收款二维码：',
		title40: '返回重新输入',
		title41: '确认充值',
		title42: '注：为了可以快速上分,请按照系统提示金额进行转帐,',
		title43: '转帐金额精确到小数点后两位,如不是按照提示金额转帐系统会自动拒绝该转帐请求',
		title44: '并且不做进行补分操作！ 谢谢',
		title45: '複制成功！',
		title46: '请输入充值金额',
		title47: '请输入付款姓名',
		title48: '确认已经转帐到指定帐户,申请上分？',
		title49: '确认',
		title50: '网络异常',
		title51: '账户号码：',
		title52: '转速快',
		title53: 'USDT',
		title54: '中国工商银行',
		title55: '中国建设银行',
		title56: '中国农业银行',
		title57: '中国银行',
		title58: '中国交通银行',
		title59: '招商银行',
		title60: '广发银行',
		title61: '平安银行',
		title62: '浦发银行',
		title63: '民生银行',
		title64: '中国邮政储蓄银行',
		title65: '华夏银行',
		title66: '深圳发展银行',
		title67: '中信银行',
		title68: '兴业银行',
		title69: '光大银行',
		title70: '渣打银行',
		title71: '汇丰银行',
		title72: '中兴银行',
		title73: '恒生银行',
		title74: '创新银行',
		title75: '请输入账户名称',
		title76: '取款金额HKD：',
		title77: '最低100',
		title78: '提交 CLICK',
		title79: '备注：',
		title80: '如提现出现打码量不足,而您的流水已经超过了充值金额的八倍,请您稍后再来提现,因为游戏方下注数据没有返还到本现金站,造成您的流水不能及时更新,造成的不便请您谅解,或者联系线上客服！',
		title81: '请选择提现通道',
		title82: '姓名',
		title83: '收款账号',
		title84: '提款金额',
		title85: '提款密码',
		title86: '银行卡管理',
		title87: '新增银行户口',
		title88: '户口号码',
		title89: '收款姓名',
		title90: '状态',
		title91: '绑定',
		title92: '银行卡',
		title93: '转数快',
		title94: '转数快ID',
		title95: '测试',
		title96: '正常',
		title97: '是',
		title98: '请玩家在注册的时候姓名务必要填写与自己的银行户口一样，因为姓名与户口不匹配，我方系统将无法出款给玩家。本公司将有权利一概不负责，如有不便敬请见谅！',
		title99: '类型：',
		title100: '收款姓名：',
		title101: '请输入收款姓名',
		title102: '银行名称：',
		title103: '所属银行：',
		title104: '收款账号：',
		title105: '请输入收款账号',
		title106: '取消',
		title107: '请输入姓名',
		title108: '请选择银行名称',
		title109: '请输入转数快银行名称',
		title110: '请输入姓名收款帐号',
		title111: '请输入地址',
		title112: '请输入转数快所属银行',
		title113: '交易历史',
		title114: '种类',
		title115: '存款',
		title116: '提现',
		title117: '投注',
		title118: '返水',
		title119: '额度转换',
		title120: '开始日期',
		title121: '选择开始时间',
		title122: '结束日期',
		title123: '选择结束日期',
		title124: '提交 CLICK',
		title125: '创建时间',
		title126: '状态',
		title127: '种类',
		title128: '金额',
		title129: '成功',
		title130: '元',
		title131: '显示第',
		title132: '至',
		title133: '项结果共',
		title134: '项',
		title135: '上页',
		title136: '下页',
	},
	login: {
		username_placeholder: '请输入账号 *',
		username_alert: '请输入账号',
		password_placeholder: '请输入密码 *',
		password_alert: '请输入密码',
		password_alert_length: '密码必须大于6位',
		wangjimima: '忘记密码',
		zhuce: '立即注册',
		denglu_btn: '登入',
		wangji: ['微信', '在线客服', "立即注冊", "游客进入"]
	},
	newdasf: {
		title: ["下 载", "我的余额", "最低充值", "最低提款"]
	},
	zhuce: {
		top_right: '已有账号，下载APP',
		username_placeholder: '请输入账号 *',
		username_alert: '请输入账号',
		password_placeholder: '请输入密码 *',
		password_alert: '请输入密码',
		password_alert_length: '密码必须大于6位',
		password_alert_buyizhi: '两次密码不一致',
		password2_placeholder: '请再次输入密码 *',
		password2_alert: '请再次输入密码',
		qk_password_placeholder: '请输入取款密码 *',
		qk_password_alert: '请输入取款密码',
		qk_password_alert_length: '取款密码必须大于6位',
		phone_placeholder: '请输入手机号 *',
		phone_alert: '请输入手机号',
		code_placeholder: '请输入验证码 *',
		code_alert: '请输入验证码',
		huoqu_btn: '获取验证码',
		index: '返回首页',
		zhuce: '注册'
	},
	index: {
		top: ['英属维京群岛', '官方运营', '英属维京群岛', '（bvi）认证', '存款', '取款'],
		nav: ['首页', '休闲区', '充值', '提现', '彩票', '电子', '视讯', '棋牌', '体育', '捕鱼', '语言'],
		youxi: ['您需要为【show_title】转入金额吗?', '当前余额', '回收中...', '请输入金额', '全部', '直接进入游戏', '立即转入游戏']
	},
	bottom: {
		nav: ['活动中心', '线上客服', '首页', '额度转换', '我的'],
		navTow: ['福利', '客服', '首页', '更多', '用户户口'],
	},
	gerenzhongxin: {
		xiugaimima: '修改密码',
		lingqufuli: '領取每日福利',
		dailizhongxin: '代理中心',
		zhuzhanghuyue: '主账户余额',
		benyuexiazhuzonge: '下注总额',
		chongzhi: '充值',
		fanshui: '返水',
		tixian: '提现',
		xiaoxi: '消息',
		fulihuodong: '福利活动',
		baobiao: '报表',
		shishifanshui: '实时返水',
		shishifanshui_str: '自主洗码,实时结算,请投注达标后再试',
		tikuanliushuizhi: "提款流水值",
		zhanghuyue: "账户余额",
		zijinhuishou: "资金回收",
		yinhanghukou: "银行户口",
		lishuxiaji: "隶属下级",
		xiangxi: "详细",
	},
	xiaoxi: {
		title: '消息中心',
		table: ['主题', '内容', '发送时间']
	},
	gerenxinxi: {
		title: '个人信息',
		bangdingshouji: '绑定手机',
		xiugaizhifumima: '修改支付密码'
	},
	bangding: {
		title: '绑定手机',
		yibangding: '已绑定手机号',
		shoujihao: '手机号',
		yanzhengma: '验证码',
		jiumima: '旧密码',
		xinmima: '新密码',
		querenmima: '确认密码'
	},
	jilv: {
		cunkuan: '存款',
		tixian: '提现',
		touzhu: '投注',
		fanshui: '返水',
		erdu: '额度转换',
		riqi_title: '选择日期',
		riqi: ['今日', '2天', '3天', '5天', '7天', '30天'],
		zongchenggongtixian: '总成功充值',
		tixianchenggong: '提现成功',
		cunkuan_tab: ['金额', '状态', '时间'],
		touzhu_tab: ['玩法', '投注', '中奖金', '时间'],
		eduzhuanhuan_tab: ['金额', '类型', '时间']
	},
	erduzhuanhuan: {
		chonghuijinge: '重绘金额',
		zijinhuishou: '资金回收',
		shengyu: '剩余码量:',
		zhongxinqianbao: '中心钱包',
		youxiqianbao: '游戏钱包',
		zhuanzhang_placeholder: '请输入转帐金额',
		lijizhuanzhang: '立即转帐',
		alt1: '两边游戏ID不可一致'
	},
	dailizhongxin: {
		tuiguanglianjie: '推广链接',
		fuzhilianjie: '复制链接',
		fuzhichenggong: '复制成功 !',
		erweimatuiguang: '二维码推广',
		yongjinfafang: '佣金发放',
		huiyuangcunkuang: '会员存款',
		huiyuangtixian: '会员提现',
		huiyuan: '会员',
		huiyuanshuying: '会员输赢',
		shenqinghuodong: '申请活动'
	},
	bangzhuzhongxin: {
		nav: ['活动中心', '客服中心', '额度转换', '个人中心']
	}
}
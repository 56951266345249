export default {
	title: 'Website name',
	fuzhi:'copy',
	zongjine:'total',
	h_title: {
		shouye: 'Home',
		login: 'Login',
		hudong: 'Activity',
		xianshangkefu: 'online customer service',
		chongzhi: 'recharge',
		erduzhuanhuan: 'quota conversion',
		erduzhuanhuanTow: "Virtual currency recharge",
		gerenzhongxin: 'mine',
		gerenxinxi: 'mine',
		bangdingshouji: 'modify password',
		dailizhongxin: 'agent center',
		xiaoxi: 'message',
		tixian: 'withdrawal',
		baobiao_cunkuang: 'deposit',
		baobiao_tixian: 'withdrawal',
		baobiao_touzhu: 'betting',
		baobiao_fanshui: 'return water',
		baobiao_eduzhuanhuan: 'quota conversion',
		zhuce: 'register',
		xiugaixhifumima: 'modify payment password',
		huodongxiangqing: 'event details',
		zizhufuwu: 'self service',
		zizhufuwu_list: 'help center',
		zizhufuwu_list2: 'Next tutorial',
		zizhufuwu_list3: 'Upper Course',
		msg: 'Notice Announcement'
	},
	newList: {
		title1: 'No Record',
		title2: 'Deposit',
		title3: 'FPS',
		title4: 'Convenience store value-added',
		title5: 'Virtual Currency Payment',
		title6: 'Alipay scan code recharge',
		title7: 'Consumption Voucher Payment',
		title8: 'Deposit amount:',
		title9: 'Please enter the amount to recharge',
		title10: 'Payment Name:',
		title11: 'Please enter the name of the payment',
		title12: 'Deposit Bonus:',
		title13: 'No Bonus Stored Value (2x Rollover)',
		title14: 'Unlimited Credits Get 10% Free (10x Rollover)',
		title15: 'Get 20% free for your first daily deposit (20x rollover)',
		title16: 'How to recharge:',
		title17: 'FPS',
		title18: 'Convenience Store Value-added',
		title19: 'Virtual Currency Payment',
		title20: 'Alipay scan code recharge',
		title21: 'Get beneficiary bank information',
		title22: 'Return to re-entry',
		title23: ' * Note: The remittance needs to be remitted according to the balance displayed by the system',
		title24: 'Not accepting remittances:',
		title25: 'A Cash Deposit',
		title26: 'B ATM Deposits',
		title27: 'C Check Deposit',
		title28: 'D Remittance limit less than 100',
		title29: 'E Unauthorized payment, the remittance amount does not match the submitted amount',
		title30: 'F system name does not match bank name',
		title31: 'In the event of any of the above regulations, it will not be dealt with. ',
		title32: 'The company is not responsible for any errors caused by any situation, please understand',
		title33: 'Beneficiary Bank',
		title34: 'Please record the following payment information and send money',
		title35: 'Receiving Account Number:',
		title36: 'Beneficiary Account Name:',
		title37: 'QR code for receiving payments:',
		title38: 'Deposit Address (TRC):',
		title39: 'QR code for receiving payments:',
		title40: 'Return to re-enter',
		title41: 'Confirm Deposit',
		title42: 'Note: In order to quickly score points, please transfer the amount prompted by the system,',
		title43: 'The transfer amount is accurate to two decimal places, if you do not transfer the amount according to the prompted amount, the system will automatically reject the transfer request',
		title44: "And don't make up the score!' Thanks",
		title45: 'Replication Success!',
		title46: 'Please enter the amount to top up',
		title47: 'Please enter the name of the payment',
		title48: 'Confirm that the transfer has been made to the designated account, apply for a score?',
		title49: 'Confirmed',
		title50: 'Network Exception',
		title51: 'Account Number:',
		title52: 'Spin fast',
		title53: 'USDT',
		title54: 'ICBC',
		title55: 'China Construction Bank',
		title56: 'Agricultural Bank of China',
		title57: 'Bank of China',
		title58: 'Bank of Communications',
		title59: 'China Merchants Bank',
		title60: 'China Guangfa Bank',
		title61: 'Ping An Bank',
		title62: 'SPD Bank',
		title63: 'Minsheng Bank',
		title64: 'Postal Savings Bank of China',
		title65: 'Hua Xia Bank',
		title66: 'Shenzhen Development Bank',
		title67: 'CITIC Bank',
		title68: 'Industrial Bank',
		title69: 'Everbright Bank',
		title70: 'Standard Chartered Bank',
		title71: 'HSBC',
		title72: 'ZTE Bank',
		title73: 'Hang Seng Bank',
		title74: 'Innovation Bank',
		title75: 'Please enter an account name',
		title76: 'Withdrawal AmountHKD:',
		title77: 'Minimum 500',
		title78: 'Submit CLICK',
		title79: 'Remarks:',
		title80: 'If the amount of withdrawal is insufficient, and your turnover has exceeded eight times of the deposit amount, please come back to withdraw later, because the game side betting data has not been returned to this cash station, resulting in your turnover can not be updated in time, please forgive me for the inconvenience, or contact online customer service! ',
		title81: 'Please select a withdrawal channel',
		title82: 'Name',
		title83: 'Beneficiary Account Number',
		title84: 'Withdrawal Amount',
		title85: 'Withdrawal Password',
		title86: 'Card Management',
		title87: 'New Bank Account',
		title88: 'Account Number',
		title89: 'Beneficiary Name',
		title90: 'Status',
		title91: 'Binding',
		title92: 'Bank card',
		title93: 'FPS',
		title94: 'FPS ID',
		title95: 'Test',
		title96: 'Normal',
		title97: 'Yes',
		title98: 'Please be sure to fill in the name of the player when registering with your bank account, because the name does not match the account, our system will not be able to withdraw funds to the player. The company will have the right not to be responsible, please forgive me for any inconvenience!',
		title99: 'Type:',
		title100: 'Beneficiary Name:',
		title101: 'Please enter the name of the recipient',
		title102: 'Bank Name:',
		title103: 'Bank:',
		title104: 'Beneficiary Account Number:',
		title105: 'Please enter the receiving account number',
		title106: 'Cancel',
		title107: 'Please enter a name',
		title108: 'Please select a bank name',
		title109: 'Please enter the name of the FPS bank',
		title110: 'Please enter your name to receive the account number',
		title111: 'Please enter an address',
		title112: 'Please enter the bank to which the FPS belongs',
		title113: 'Transaction History',
		title114: 'Kind',
		title115: 'Deposit',
		title116: 'Withdrawal',
		title117: 'Bet',
		title118: 'Rebate',
		title119: 'Quota Conversion',
		title120: 'Start Date',
		title121: 'Choose Start Time',
		title122: 'End Date',
		title123: 'Choose an end date',
		title124: 'Submit CLICK',
		title125: 'Created',
		title126: 'Status',
		title127: 'Kind',
		title128: 'Amount',
		title129: 'Success',
		title130: 'Yuan',
		title131: 'Show No.',
		title132: 'To',
		title133: 'Total Results',
		title134: 'Item',
		title135: 'Previous',
		title136: 'Next',
	},
	kefuzhongxin: {
		title: 'Welcome to Customer Service Center',
		titleTow: 'At your service 24/7',
		zaixiankefu: 'Online Customer Service',
		Skype: 'Skype',
		Telegram: 'Telegram',
		Whatsapp: 'Whatsapp',
	},
	common: {
		shuaxin: 'refresh successful',
		weihu: 'being maintained...',
		gengduo: 'more',
		xiangxi: 'details',
		tuichudenglu: 'exit Login',
		queren: 'confirm',
		quxiao: 'Cancel',
		genghuan: 'see',
		qingshuru: 'please enter',
		zanwujilv: 'no record temporarily',
		lingquchenggong: 'Received successfully',
		baobiao_status: ['', 'Pending review', 'succeed', 'rejected'],
		yuan: 'RMB',
		denglu_title: 'Please log in',
		denglu_mas: 'Your is not logged in and cannot be operated. Please log in again'
	},
	newdasf: {
		title: ["Download", "My Balance", "Minimum Recharge", "Minimum Withdrawal"]
	},
	kefu: {
		caozuozhinan: 'operation guide',
		xianshangkefu: 'telegram service',
		wx: 'wechat',
		tousudianhua: 'complaint hotline'
	},
	login: {
		username_placeholder: 'please enter the account number *',
		username_alert: 'please enter the account number',
		password_placeholder: 'please enter the password *',
		password_alert: 'please enter the password',
		password_alert_length: 'password must be greater than 6 digits',
		wangjimima: 'Forgot',
		zhuce: 'Register',
		denglu_btn: 'Login',
		wangji: ['WeChat', 'Online Customer Service', "Register Now", "Visitors"]
	},
	zhuce: {
		top_right: 'existing account, Download app',
		username_placeholder: 'please enter the account number *',
		username_alert: 'please enter the account numbe',
		password_placeholder: 'please enter the password *',
		password_alert: 'please enter the password',
		password_alert_length: 'password must be greater than 6 digits',
		password_alert_buyizhi: 'the two passwords are inconsistent',
		password2_placeholder: 'please enter the password again *',
		password2_alert: 'please enter the password again',
		qk_password_placeholder: 'please enter the withdrawal password *',
		qk_password_alert: 'please enter the withdrawal password',
		qk_password_alert_length: 'withdrawal password must be greater than 6 digits',
		phone_placeholder: 'please enter your mobile number *',
		phone_alert: 'please enter your mobile number',
		code_placeholder: 'please enter the verification code *',
		code_alert: 'please enter the verification code',
		huoqu_btn: 'get verification code',
		index: 'home',
		zhuce: 'registration'
	},
	index: {
		top: ['british virgin islands', ' operation of the group', 'philippines (pagcor)', '(bvi) certification',
			'Deposit', 'Withdrawal'
		],
		nav: ['home', 'Leisure', 'recharge', 'withdrawal', 'lottery', ' electronic', 'video', 'chess', 'Sports',
			'fishing', 'language'
		],
		youxi: ['do you need to transfer in the amount for [show_title]?', 'Current balance', 'being recovered...',
			'Please enter the amount', 'all', 'directly enter the game', 'immediately enter the game'
		]
	},
	bottom: {
		nav: ['activity center', 'online', 'home', 'quota', 'mine'],
		navTow: ['welfare', 'customer service', 'home page', 'more', 'user account'],
	},
	gerenzhongxin: {
		xiugaimima: 'modify password',
		lingqufuli: 'receive daily benefits',
		dailizhongxin: 'agent center',
		zhuzhanghuyue: 'master account balance',
		benyuexiazhuzonge: 'total bet amount',
		chongzhi: 'recharge',
		fanshui: 'return water',
		tixian: 'withdrawal',
		xiaoxi: 'message',
		fulihuodong: 'welfare activities',
		baobiao: 'report',
		shishifanshui: 'real time water return',
		shishifanshui_str: 'self code washing, real-time settlement, please try again after the bet meets the standard',
		tikuanliushuizhi: "Withdrawal flow value",
		zhanghuyue: "Account balance",
		zijinhuishou: "Recovery of funds",
		yinhanghukou: "Bank account",
		lishuxiaji: "subordinate",
		xiangxi: "Details",
	},
	xiaoxi: {
		title: 'personal information',
		table: ['subject', 'content', 'sending time']
	},
	gerenxinxi: {
		title: 'personal information',
		bangdingshouji: 'bind mobile phone',
		xiugaizhifumima: 'modify payment password'
	},
	bangding: {
		title: 'bind mobile phone',
		yibangding: 'bound mobile number',
		shoujihao: 'mobile number',
		yanzhengma: 'verification code',
		jiumima: 'old password',
		xinmima: 'new password',
		querenmima: 'confirm password'
	},
	jilv: {
		cunkuan: 'deposit',
		tixian: 'withdrawal',
		touzhu: 'betting',
		fanshui: 'return water',
		erdu: 'quota conversion',
		riqi_title: 'select date',
		riqi: ['today', '2days', '3days', '5days', '7days', '30days'],
		zongchenggongtixian: 'total successful recharge',
		tixianchenggong: 'withdrawal succeeded',
		cunkuan_tab: ['amount', 'status', 'time'],
		touzhu_tab: ['playing method', 'betting', 'winning bonus', 'time'],
		eduzhuanhuan_tab: ['amount', 'type', 'time']
	},
	erduzhuanhuan: {
		chonghuijinge: 'redrawn amount',
		zijinhuishou: 'fund recovery',
		zhongxinqianbao: 'center wallet',
		youxiqianbao: 'game wallet',
		zhuanzhang_placeholder: 'please enter the transfer amount',
		lijizhuanzhang: 'immediate transfer',
		alt1: 'Game IDs on both sides cannot be consistent',
		shengyu: 'Remaining Code Quantity:'
	},
	dailizhongxin: {
		tuiguanglianjie: 'promotion link',
		fuzhilianjie: 'copy link',
		fuzhichenggong: 'replication succeeded!',
		erweimatuiguang: 'QR code promotion',
		yongjinfafang: 'commission payment',
		huiyuangcunkuang: 'member deposit',
		huiyuangtixian: 'member withdrawal',
		huiyuan: 'member',
		huiyuanshuying: 'member wins or loses',
		shenqinghuodong: 'apply for activity'
	},
	bangzhuzhongxin: {
		nav: ['activity center', 'customer service', 'quota conversion', 'personal center']
	}
}